<template>
  <q-page class="fit" :style-fn="() => ''">
    <header-bar>
      <master-data-search-bar v-model="searchTerm" class="q-ml-md" />
    </header-bar>
    <master-data-table
      square
      class="col page-below-header-bar"
      :is-loading="isLoading"
      :columns="columns"
      :data="data"
      :page-size="pageSize"
      :page="page"
      :total="total"
      :search-text="searchTerm || ''"
      @next-page="nextPage"
      @previous-page="previousPage"
      @request="handleTableRequest"
      @row-click="handleRowClick"
    />
  </q-page>
</template>

<script setup lang="ts">
import * as api from "@/api/supplier";
import HeaderBar from "@/components/Header/HeaderBar.vue";
import MasterDataSearchBar from "@/components/MasterDataPage/MasterDataSearchBar.vue";
import MasterDataTable from "@/components/MasterDataPage/MasterDataTable.vue";
import SupplierDialog from "@/components/SuppliersPage/SupplierDialog.vue";
import { useMasterDataPage } from "@/composables/useMasterDataPage";
import type { Supplier } from "@/types/supplier";
import { useQuasar, type QTableProps } from "quasar";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const columns: QTableProps["columns"] = [
  {
    name: "externalId",
    label: t("ID"),
    align: "left",
    field: "externalId",
    sortable: false,
  },
  {
    name: "name",
    label: t("Name"),
    align: "left",
    field: "name",
    sortable: false,
    style:
      "max-width: 400px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;",
  },
  {
    name: "street",
    label: t("Street"),
    align: "left",
    field: "street",
    sortable: false,
    style:
      "max-width: 500px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;",
  },
  {
    name: "postCode",
    label: t("Post code"),
    align: "left",
    field: "postCode",
    sortable: false,
    style:
      "max-width: 500px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;",
  },
  {
    name: "town",
    label: t("Town"),
    align: "left",
    field: "town",
    sortable: false,
    style:
      "max-width: 500px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;",
  },
  {
    name: "region",
    label: t("Region"),
    align: "left",
    field: "region",
    sortable: false,
    style:
      "max-width: 500px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;",
  },
  {
    name: "countryCode",
    label: t("Country code"),
    align: "left",
    field: "countryCode",
    sortable: false,
    style:
      "max-width: 50px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;",
  },
];

const {
  isLoading,
  data,
  pageSize,
  page,
  total,
  searchTerm,
  nextPage,
  previousPage,
  handleTableRequest,
} = useMasterDataPage(api.listSuppliers);

const q = useQuasar();

const handleRowClick = (event: PointerEvent, supplier: Supplier) => {
  q.dialog({
    component: SupplierDialog,
    componentProps: {
      supplier,
    },
  });
};
</script>
